.file-input-container {
  margin-top: 0.25rem; /* mt-1 */
  display: block; /* block */
  width: 100%; /* w-full */
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* text-gray-500 */
}

.file-input {
  margin-right: 1rem; /* file:mr-4 */
  padding: 0.5rem 1rem; /* file:py-2 file:px-4 */
  border-radius: 0.25rem; /* file:rounded */
  border: none; /* file:border-0 */
  font-size: 0.875rem; /* file:text-sm */
  font-weight: 600; /* file:font-semibold */
  color: rgb(0 136 205); /* file:text-blue-700 */
}

.file-input:hover {
  background-color: #dbeafe; /* hover:file:bg-blue-100 */
}

.preview-img{
  max-width: 100px;
  max-height: 100px;
  margin-left: 1rem;
  border-radius: 9999px;
}