.j-button {
  width: 150px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  color: #464444;
  margin: 1px 1px;
  border-radius: 4px;
  border: 1px;
}

.j-button:hover {
  cursor: pointer;
}
.j-active {
  background-color: #0088cc;
  color: #fff !important;
}

.j-box {
  width: 100%;
  height: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f7f9fb;
  border: 0.5px solid rgba(114, 138, 150, 0.16);
  border-radius: 40px;
  padding-right: 5px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.j-node{
    font-size: 14px;
    opacity: 0.3;
    padding-left: 18px;
    margin: 0;
    font-family: mulish, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 5px;
}

.j-input {
  opacity: 1;
  flex: 1;
  height: 100%;
  border: unset;
  text-indent: 16px;
  background: transparent;
  outline: none;
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  caret-color: #728a96;
}

.j-textarea{
    background: transparent;
    width: 100%;
    border-radius: 10px;
    font-weight: 400;
    font-size: 1rem;
    outline: none;
    caret-color: #728a96;
    border-color: #e4e9ec;
    padding: 18px;
    min-height: 300px;
    white-space: pre-wrap;
}